import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GridLayout from "../../components/GridLayout"
import PageLayout from "../../components/PageLayout"
import GridTextProjectElement from "../../components/GridTextProjectElement"
import { projectNames } from "../../context/consts"
import Seo from "../../components/Seo"


const MamehaWalletPage = () => {

    const imageComponents = [
        <StaticImage key={0} src="./../../images/mameha/mameha-0.jpg" alt="" layout="fullWidth"></StaticImage>,
        <GridTextProjectElement key={1} projectName={projectNames.MAMEHA_WALLET} />,
        <StaticImage key={2} src="./../../images/mameha/mameha-1.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={3} src="./../../images/mameha/mameha-2.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={4} src="./../../images/mameha/mameha-3.jpg" alt="" layout="fullWidth"></StaticImage>,
        <StaticImage key={5} src="./../../images/mameha/mameha-4.jpg" alt="" layout="fullWidth"></StaticImage>,
    ]

    return (
        <PageLayout firstHead="mameha" secondHead="oilwalletdispenser">
            <GridLayout components={imageComponents}></GridLayout>
            <Seo subTitle="mameha oilwalletdispenser" />
        </PageLayout>
    )

}

export default MamehaWalletPage